.gameInfo {
  margin-top: 25px;
}

.league-game {
  display: flex; /* Use flexbox for layout */
  justify-content: space-around; /* Evenly space items */
  align-items: center; /* Vertically align items */
  background-color: var(--main-bg-color-dark);
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.league-game .user {
  font-size: 12px;
  color: var(--text-color-grey-light);
  text-align: left;
}

.league-game .team-name {
  font-size: 18px;
}
.league-game .score {
  font-size: 18px;
}

.league-game .score .winner-box {
  font-size: 18px;
}

.league-game .score .loser-box {
  font-size: 18px;
}

.league-game .left-align {
  text-align: left;
  align-items: flex-start;
}
.league-game .right-align {
  text-align: right;
  align-items: flex-end;
}

.league-game .ownersTeam .team-name {
  color: var(--text-highlight-color)
}

.league-game .vs {
  margin: 0 10px; /* Add spacing around "vs" */
  text-align: center;
  
}

.score-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

@media (max-width: 768px) {
  .score-container {
    flex-direction: column;
    align-items: flex-start;
  }
}

.score-position {
  font-size: clamp(14px, 3vw, 16px);
  font-weight: bold;
  text-align: center;
}

.score-position-title {
  font-weight: bold;
}

.starter-team-name {
  font-size: clamp(18px, 4vw, 24px);
  text-align: left;
  font-weight: bold;
}

.starter-main-score {
  font-size: clamp(12px, 2.5vw, 13px);
  font-weight: bold;
  color: var(--text-color-grey);
  
  @media (max-width: 768px) {
    align-self: flex-start;
  }
}

.starters-head-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  
}

// For starters and bench
.head-row {
  background-color: var(--main-bg-color-dark);
}

.bench-table {
  margin-top: 45px;
  
}

.head-text {
  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: bold;
  
  @media (max-width: 768px) {
    font-size: clamp(16px, 4vw, 20px) !important;
  }
}

.starters .head-text {
  font-size: 24px;
}

.bench .head-text {
  font-size: 20px;
}

.starters .gameState {
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 18px;
  gap: 4px;
  
  @media (max-width: 768px) {
    justify-content: left;
    font-size: clamp(14px, 3.5vw, 16px);
    flex-wrap: wrap;
  }
}

.starters .gameState .Final {
  color: var(--complete-color);
  
  @media (max-width: 768px) {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .starters.head-row td {
    padding: 8px 4px;
  }
  
  .starters.head-row td:first-child {
    width: 30%;
  }
  
  .starters.head-row td:last-child {
    width: 40%;
  }
}

.winning-team {
  color: var(--winner-color);
}

.winner-box {
  color: var(--winner-color);
  font-size: 13px;
}

.losing-team {
  color: var(--loser-color);
}

.loser-box {
  color: var(--loser-color);
  font-size: 13px;
}

.score-header-canvas {
  display: block; /* or inline-block, depending on your layout */
  /* Add any other CSS properties you need to maintain the layout */
}

.starter-player {
  font-size: clamp(14px, 3vw, 16px);
  text-align: left;
  font-weight: normal;
  display: inline-block;
  
  @media (max-width: 768px) {
    margin-bottom: 4px;
  }
}

.starter-player-score {
  font-size: clamp(12px, 2.5vw, 14px);
  text-align: right;
  margin-right: 10px;
  color: var(--text-color-grey);
  font-weight: normal;
  display: inline-block;
  
  @media (max-width: 768px) {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.starter-player-opponent {
  font-size: clamp(12px, 2.5vw, 14px);
  text-align: left;
  color: var(--text-color-grey);
  font-weight: normal;
  display: inline-block;
  
  @media (max-width: 768px) {
    margin-top: 4px;
  }
}

.matchup-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
  padding: 8px;
  margin: 8px 0;
  max-width: 100%;
  overflow-x: auto;
}

.matchup-link {
  font-weight: bold !important;
  font-size: clamp(14px, 3vw, 18px) !important;
  min-width: 36px !important;
  padding: 4px !important;
  
  @media (max-width: 768px) {
    min-width: 32px !important;
    padding: 2px !important;
  }
}

.player-info-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.player-info-table .team-row {
  width: 45%;
  
  @media (max-width: 768px) {
    width: 42%;
  }
}

.player-info-table .position-row {
  width: 10%;
  
  @media (max-width: 768px) {
    width: 16%;
  }
}

@media (max-width: 768px) {
  .player-info-table td,
  .player-info-table th {
    padding: 8px 4px;
  }
  
  .starters-table {
    margin-top: 16px;
  }
  
  .bench-table {
    margin-top: 24px;
  }
}